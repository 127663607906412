<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'exercises' }"
          >Exercises</router-link
        >
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <v-btn @click="deleteConfirmDialog = true" color="error" text dark>
        <v-icon class="mr-3">
          {{ icons.delete }}
        </v-icon>
        Delete Exercise
      </v-btn>
    </v-app-bar>

    <v-divider></v-divider>

    <v-row>
      <v-col sm="12" lg="8">
        <div class="pa-8">
          <v-form v-if="form" @submit.prevent="saveExercise" ref="form">
            <div class="d-flex justify-space-between align-center mb-8">
              <h2 class="mr-auto">Exercise Details</h2>
              <input
                type="file"
                ref="videoAttachment"
                accept="video/mp4,video/quicktime,video/avi,video/mpeg"
                @change="updateVideoAttachment"
                style="display: none"
              />
              <v-btn
                class="mr-2"
                color="primary"
                width="150px"
                :loading="videoLoading"
                @click="changeVideoAttachment"
                depressed
              >
                {{ form.video ? 'Update Video' : 'Upload Video' }}
              </v-btn>
              <v-btn
                v-if="form.video"
                class="mr-2"
                color="primary"
                width="150px"
                :loading="videoLoading"
                @click="deleteVideoAttachment"
                depressed
              >
                Delete Video
              </v-btn>
              <v-btn
                type="submit"
                color="primary"
                width="150px"
                :loading="form.$busy"
                depressed
              >
                Save
              </v-btn>
            </div>
            <v-row>
              <v-col>
                <image-upload
                  :image="exercise.image"
                  @change="imageChange"
                  @removed="imageRemoved"
                  :imageLoading="imageLoading"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  required
                  label="Name *"
                  hide-details="auto"
                  v-model="form.name"
                  :error-messages="form.$getError('name')"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  outlined
                  label="Status *"
                  hide-details="auto"
                  :items="statuses"
                  persistent-placeholder
                  v-model="form.status"
                  :error-messages="form.$getError('status')"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="form.disclaimer"
                  outlined
                  required
                  label="Disclaimer"
                  hide-details="auto"
                  :error-messages="form.$getError('disclaimer')"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <div class="my-6">
              <div class="d-flex justify-space-between align-center mb-8">
                <div class="mr-auto">
                  <h4>
                    <v-progress-circular
                      v-if="draggableStatus"
                      indeterminate
                      color="primary"
                      size="20"
                      width="3"
                      class="mr-1"
                    ></v-progress-circular>
                    Steps
                  </h4>
                  <span class="caption">
                    You can rearrange the card sequence by clicking and dragging
                    it.
                  </span>
                </div>
                <v-btn
                  small
                  color="primary"
                  width="150px"
                  @click="addStepDialog = true"
                  depressed
                >
                  <v-icon left> {{ icons.add }}</v-icon> Add Step
                </v-btn>
              </div>
              <draggable
                v-model="form.steps"
                class="row"
                @change="sortSteps"
                :class="{ draggableStatus }"
              >
                <v-col
                  v-for="(step, index) in form.steps"
                  :key="index"
                  cols="6"
                >
                  <v-card class="px-4 mt-4 mb-2">
                    <v-chip class="ma-2 floatingCounter" color="primary">
                      {{ index + 1 }}
                    </v-chip>
                    <v-row align="center" class="py-5">
                      <v-col sm="12" md="12">
                        <v-textarea
                          outlined
                          required
                          hide-details="auto"
                          label="Description *"
                          v-model="step.description"
                          :rules="rules.required"
                        >
                        </v-textarea>
                      </v-col>
                      <v-col sm="6">
                        <v-text-field
                          outlined
                          required
                          hide-details="auto"
                          label="Duration *"
                          v-model="step.duration"
                          :rules="rules.required"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col sm="6">
                        <v-select
                          outlined
                          required
                          hide-details="auto"
                          label="Unit *"
                          v-model="step.duration_unit"
                          @input="getLabelDetail"
                          :items="durationUnits"
                        ></v-select>
                      </v-col>
                      <v-col sm="12" md="12">
                        <v-text-field
                          outlined
                          required
                          hide-details="auto"
                          label="Note"
                          v-model="step.note"
                        >
                        </v-text-field>
                      </v-col>
                      <v-btn-toggle rounded class="floating">
                        <v-btn
                          x-small
                          fab
                          icon
                          @click="stepUpdate(step)"
                          :loading="stepUpdateLoading"
                        >
                          <v-icon color="blue">{{ icons.save }}</v-icon>
                        </v-btn>
                        <v-btn
                          x-small
                          fab
                          icon
                          @click="
                            deleteStepConfirmDialog = true
                            deleteExerciseStepId = step.id
                          "
                        >
                          <v-icon color="red">{{ icons.delete }}</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </v-row>
                  </v-card>
                </v-col>
              </draggable>
            </div>
          </v-form>
        </div>
      </v-col>
      <v-col sm="12" lg="4">
        <PreviewExercise :exercise="this.exercise"></PreviewExercise>
      </v-col>
    </v-row>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <AddStepDialog
      v-model="addStepDialog"
      :exercise="this.exercise"
    ></AddStepDialog>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete exercise"
      message="Are you sure you want to delete this exercise?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteExercise"
    ></ConfirmModal>

    <ConfirmModal
      v-model="deleteStepConfirmDialog"
      title="Delete Step"
      message="Are you sure you want to delete this step?"
      @cancel="deleteStepConfirmDialog = false"
      @confirm="stepRemoved"
    ></ConfirmModal>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ImageUpload from '@/components/ImageUpload'
import { mdiChevronLeft, mdiContentSave, mdiTrashCan, mdiPlus } from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
import Form from '@/utils/form'
import AddStepDialog from './components/AddStepDialog.vue'
import ConfirmModal from '@/components/modals/ConfirmModal'
import PreviewExercise from './components/PreviewExercise.vue'
import draggable from 'vuedraggable'

export default {
  name: 'ExercisesDetails',
  components: {
    AppBarNavIcon,
    ImageUpload,
    AddStepDialog,
    ConfirmModal,
    PreviewExercise,
    draggable
  },
  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        save: mdiContentSave,
        delete: mdiTrashCan,
        add: mdiPlus
      },
      form: null,
      loading: false,
      imageLoading: false,
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      addStepDialog: false,
      deleteConfirmDialog: false,
      deleteStepConfirmDialog: false,
      deleteExerciseStepId: null,
      rules: {
        required: [value => String(value).length > 0 || 'Required']
      },
      durationUnits: [
        { text: 'Minutes', value: 'minutes' },
        { text: 'Seconds', value: 'seconds' }
      ],
      statuses: [
        { text: 'Active', value: 1 },
        { text: 'Disabled', value: 0 }
      ],
      videoLoading: false,
      stepUpdateLoading: false,
      stepSortLoading: false
    }
  },
  computed: {
    ...mapState({
      exercise: state => state.exercise.exerciseDetails
    }),
    draggableStatus() {
      return this.stepSortLoading
    }
  },
  methods: {
    ...mapActions({
      deleteExerciseAction: 'exercise/deleteExercise',
      getExerciseDetailsAction: 'exercise/getExerciseDetails',
      changeImage: 'exercise/changeImage',
      removeImage: 'exercise/removeImage',
      removeStep: 'exercise/deleteStep',
      updateStep: 'exercise/updateStep',
      updateExercise: 'exercise/updateExercise',
      updateExerciseVideo: 'exercise/updateExerciseVideo',
      deleteExerciseVideo: 'exercise/deleteExerciseVideo',
      sortExerciseStep: 'exercise/sortSteps'
    }),

    ...mapMutations({
      clearExerciseDetails: 'exercise/clearExerciseDetails'
    }),

    async deleteExercise() {
      this.loading = true
      await this.deleteExerciseAction(this.$route.params.id)
      this.loading = false
      this.$router.replace('/exercises')
    },

    thumbnailSrc(image) {
      return image
        ? image.thumb_url
        : require('@/assets/images/default-image.jpeg')
    },

    async imageChange(image) {
      this.imageLoading = true
      let data = new FormData()
      data.append('exercise_id', this.exercise.id)
      data.append('image', image)
      await this.changeImage(data)
      this.imageLoading = false
      this.showSnackbar('Exercise image successfully updated!', 'success')
    },

    async imageRemoved() {
      this.exercise.image = null
      this.removeImage(this.$route.params.id)
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    async stepRemoved() {
      await this.removeStep({
        exerciseId: this.$route.params.id,
        exerciseStepId: this.deleteExerciseStepId
      })
      await this.getExerciseDetailsAction(this.$route.params.id)
      this.deleteStepConfirmDialog = false
      this.showSnackbar('Exercise Step has been deleted!', 'success')
    },

    async stepUpdate(form) {
      this.stepUpdateLoading = true

      await this.updateStep({
        ...form,
        exerciseId: this.$route.params.id
      })
      await this.getExerciseDetailsAction(this.$route.params.id)
      this.stepUpdateLoading = false
      this.showSnackbar('Exercise Step has been updated!', 'success')
    },

    async saveExercise() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateExercise(this.form.$data())
        .then(() => {
          this.showSnackbar('Exercise successfully updated!', 'success')
        })
        .catch(err => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    getLabelDetail(event) {
      const selectedLabel = this.durationUnits.find(
        unit => unit.value === event
      )
      this.form.duration_unit = selectedLabel.text
    },

    changeVideoAttachment() {
      this.$refs['videoAttachment'].value = null
      this.$refs['videoAttachment'].click()
    },

    async updateVideoAttachment(event) {
      this.videoLoading = true

      const data = new FormData()
      const file = event.target.files[0]

      data.append('video', file)
      data.append('id', this.$route.params.id)
      this.updateExerciseVideo(data)
        .then(() => {
          this.showSnackbar('Video has been uploaded', 'success')
        })
        .catch(() => {
          this.showSnackbar(
            'There is something wrong with the attachment',
            'danger'
          )
        })
        .finally(() => {
          this.videoLoading = false
        })
    },

    async deleteVideoAttachment() {
      this.videoLoading = true

      this.deleteExerciseVideo(this.$route.params.id)
        .then(() => {
          this.getExerciseDetailsAction(this.$route.params.id)
          this.form = new Form(this.exercise)
          this.showSnackbar('Video has been deleted', 'success')
        })
        .catch(() => {
          this.showSnackbar('Something went wrong', 'danger')
        })
        .finally(() => {
          this.videoLoading = false
        })
    },

    async sortSteps() {
      this.stepSortLoading = true

      const data = {
        id: this.$route.params.id,
        steps: this.form.steps.map(step => step.id)
      }

      this.sortExerciseStep(data)
        .then(_ => {})
        .catch(_ => {
          this.showSnackbar('Something went wrong when sorting', 'danger')
        })
        .finally(_ => {
          this.stepSortLoading = false
        })
    }
  },
  async created() {
    this.loading = true
    await this.getExerciseDetailsAction(this.$route.params.id)
    this.form = new Form(this.exercise)
    this.loading = false
  },
  destroyed() {
    this.clearExerciseDetails()
  },
  watch: {
    exercise() {
      this.form = new Form(this.exercise)
    }
  }
}
</script>

<style lang="css" scoped>
.floating {
  position: absolute;
  top: -10px;
  right: -7px;
}

.floatingCounter {
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -13px !important;
}

.row.draggableStatus {
  pointer-events: none;
}
</style>
