<template>
  <v-card v-if="exercise" class="mx-auto my-12" max-width="374">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img height="250" :src="thumbnailSrc(exercise.image)"></v-img>

    <v-card-title>{{ exercise.name }}</v-card-title>

    <v-list-item dense>
      <v-list-item-icon>
        <v-icon>{{ icons.duration }}</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>
        {{ exercise.total_duration }}</v-list-item-subtitle
      >
    </v-list-item>

    <v-divider class="ma-4"></v-divider>

    <v-card-title>Instructions:</v-card-title>

    <template v-if="exercise.steps.length > 0">
      <v-timeline dense>
        <v-timeline-item v-for="(step, index) in exercise.steps" :key="index">
          <template v-slot:icon>
            <span class="white--text">{{ index + 1 }}</span>
          </template>
          <v-row class="pt-1 mr-5">
            <v-col>
              <template
                ><v-tooltip v-if="step.note" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon x-small color="blue" v-bind="attrs" v-on="on">{{
                      icons.info
                    }}</v-icon>
                  </template>
                  <span>{{ step.note }}</span>
                </v-tooltip>
                {{ step.description }}</template
              >
              <div class="text-caption">
                <v-icon x-small>{{ icons.duration }}</v-icon>
                {{ step.duration_description }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </template>

    <div class="px-4 pb-5 text-caption" v-else>No instructions</div>
  </v-card>
</template>

<script>
import { mdiClock, mdiWatch, mdiInformation } from '@mdi/js'

export default {
  props: {
    exercise: Object
  },

  data: () => ({
    icons: {
      duration: mdiClock,
      watch: mdiWatch,
      info: mdiInformation
    }
  }),

  methods: {
    thumbnailSrc(image) {
      return image ? image.url : require('@/assets/images/default-image.jpeg')
    }
  }
}
</script>
