<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="addStep" ref="form">
        <v-card>
          <v-card-title>Add Exercise Step</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    required
                    label="Description *"
                    hide-details="auto"
                    v-model="form.description"
                    :error-messages="form.$getError('description')"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    required
                    hide-details="auto"
                    label="Duration *"
                    v-model="form.duration"
                    :error-messages="form.$getError('duration')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    outlined
                    required
                    hide-details="auto"
                    label="Unit *"
                    v-model="form.duration_unit"
                    :items="durationUnits"
                    :error-messages="form.$getError('duration_unit')"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    required
                    label="Note"
                    hide-details="auto"
                    v-model="form.note"
                    :error-messages="form.$getError('note')"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import Exercise from '@/models/Exercise'
import ExerciseStep from '@/models/ExerciseStep'
import { mapActions } from 'vuex'

export default {
  props: {
    exercise: Exercise,
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: this.value,
      form: new Form({
        description: null,
        duration: 0,
        duration_unit: 'minutes',
        note: null
      }),
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      durationUnits: [
        { text: 'Minutes', value: 'minutes' },
        { text: 'Seconds', value: 'seconds' }
      ]
    }
  },

  methods: {
    ...mapActions({
      getExerciseDetailsAction: 'exercise/getExerciseDetails'
    }),

    addStep() {
      this.form.$busy = true
      this.form.$clearErrors()
      new ExerciseStep(this.form.$data())
        .for(this.exercise)
        .save()
        .then(res => {
          this.getExerciseDetailsAction(this.$route.params.id)
        })
        .then(() => {
          this.closeDialog()
          this.showSnackbar(
            'New step for exercise has been created!',
            'success'
          )
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.form.$clearErrors()
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    }
  }
}
</script>
